import React, { useEffect } from "react"
import { navigate } from "gatsby"

const PageNotFound = () => {
  useEffect(() => {
    navigate("/")
  })

  return <p>404</p>
}

export default PageNotFound
